import * as React from 'react';
import {
  Labeled,
  RecordContextProvider,
  ImageInput,
  ImageField,
} from 'react-admin';
import {
  Grid,
  Typography,
  Divider,
  Button
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PreviewIcon from '@mui/icons-material/Preview';
import PrintIcon from '@mui/icons-material/Print';
import EditDialog from 'src/components/EditDialog';
import formatCurrency from 'src/utilities/formatCurrency';
import QuotesList from '../orders/QuotesList';
import ItemMemoDialog from '../orders/dialogs/ItemMemoDialog';
import ItemDescriptionDialog from '../shared/ItemDescriptionDialog';
import FactoryIcon from '@mui/icons-material/Factory';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EventButton from '../orders/EventButton';
import InquiriesList from './InquiriesList';
import env from 'src/env';

const labelStyle = { '& span': { fontWeight: 900 }, '& p': { marginY: '0.25em' } }

const ItemDetails = (props: { item: any, order: any }) => {
  const { item, order } = props
  return (
    <RecordContextProvider value={item}>
      <Grid container>
        <Grid item xs={1} sx={{ pt: 2 }}>
          <EditDialog
            record={item}
            buttonSize="large"
            buttonVariant="text"
            buttonColor={item.photo_url ? "primary" : "error"}
            resource="order_items"
            title={item.photo_url ? 'Replace before photo' : 'Add before photo'}
            label={item.photo_url ? <PreviewIcon /> : <AddAPhotoIcon />}
          >
            {item.photo_url && <div style={{ textAlign: 'center' }}>
              <img src={item.photo_url} alt={``} style={{ width: 'auto', maxWidth: '100%', maxHeight: 500 }} />
            </div>}
            <ImageInput source="photo" label="Photo" accept="image/*">
              <ImageField source="photo" />
            </ImageInput>
          </EditDialog>
        </Grid>
        <Grid item xs={11}>
          <Grid container>
            <Grid item flexGrow={1}>
              <Typography variant="h6">
                <strong>{item.code}</strong> &ndash; {item.repairable_name}
              </Typography>
            </Grid>
            <Grid item flexShrink={1} alignItems="end">
              <Grid container gap={0.5}>
                <Grid item>
                  {item.storefront_in_at ? (
                    <></>
                  ) : item.plant_out_at ? (
                    <EventButton
                      resource="order_items"
                      color="primary"
                      buttonLabel={<><StorefrontIcon fontSize="small" />&nbsp;Store In</>}
                      event="storefront_in"
                    />
                  ) : item.plant_in_at ? (
                    <EventButton
                      resource="order_items"
                      color="primary"
                      buttonLabel={<><LocalShippingIcon fontSize="small" />&nbsp;Plant Out</>}
                      event="plant_out"
                    />
                  ) : item.storefront_out_at ? (
                    <EventButton
                      resource="order_items"
                      color="primary"
                      buttonLabel={<><FactoryIcon fontSize="small" />&nbsp;Plant In</>}
                      event="plant_in"
                    />
                  ) : (
                    <EventButton
                      resource="order_items"
                      color="primary"
                      buttonLabel={<><LocalShippingIcon fontSize="small" />&nbsp;Store Out</>}
                      event="storefront_out"
                    />
                  )}
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    href={`${env.API_URL}/rest/order_items/${item.id}.png`}
                    target="_blank"
                  >
                    <PrintIcon fontSize="small" sx={{ px: '0 !important' }} />&nbsp;Ticket
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item flexGrow={1}>
              <Labeled label="Color" sx={labelStyle}>
                <Typography variant="subtitle2" marginBottom={1}>
                  {item.color}
                </Typography>
              </Labeled>
            </Grid>
            <Grid item flexGrow={1}>
              <Labeled label="Material" sx={labelStyle}>
                <Typography variant="subtitle2" marginBottom={1}>
                  {item.material}
                </Typography>
              </Labeled>
            </Grid>
            <Grid item flexGrow={1}>
              <Labeled label="Brand" sx={labelStyle}>
                <Typography variant="subtitle2" marginBottom={1}>
                  {item.brand}
                </Typography>
              </Labeled>
            </Grid>
          </Grid>
          <Labeled label="Customer Description" sx={labelStyle}>
            <Typography variant="subtitle2" marginBottom={1}>
              {item.description ? item.description : 'None provided.'}
            </Typography>
          </Labeled>
          <br />
          <Labeled label="Estimated Item Value" sx={labelStyle}>
            <Typography variant="subtitle2" marginBottom={1}>
              {item.estimated_value ? formatCurrency(item.estimated_value) : 'None provided.'}
            </Typography>
          </Labeled>
          <br />
          <Labeled label="Memo" sx={labelStyle}>
            <Typography variant="subtitle2">
              {item.internal_note}
            </Typography>
          </Labeled>
          <br />
          <ItemMemoDialog />
          <br />
          <Labeled label="Description" sx={{ mt: 2, mb: 1, ...labelStyle }}>
            <Typography variant="subtitle2">
              <div dangerouslySetInnerHTML={{ __html: item.customer_note }} />
            </Typography>
          </Labeled>
          <br />
          <ItemDescriptionDialog />
        </Grid>
      </Grid>
      <Divider sx={{ my: 3, backgroundColor: 'black' }} />
      <Typography variant="h6" sx={{ textTransform: 'uppercase', fontSize: '1rem', fontWeight: 600, lineHeight: '1.25em', mb: 2 }}>
        Customer inquiries
      </Typography>
      <InquiriesList />
      <QuotesList orderStatus={order.status} />
    </RecordContextProvider>
  )
}

export default ItemDetails;
