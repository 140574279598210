import React from 'react';
import { useRecordContext } from 'react-admin';
import EditDialog from 'src/components/EditDialog';
import RichTextInput from 'src/components/RichTextInput';
import { MuiButtonVariants, MuiColors } from 'types';

export default function ItemDescriptionDialog() {
  const record = useRecordContext();
  const label = record.customer_note ? 'Edit Description' : 'Add Description';
  const description = 'Provide an explanation of repairs for the customer.';
  let buttonColor: MuiColors, buttonVariant: MuiButtonVariants;
  if (record.customer_note) {
    buttonColor = 'primary';
    buttonVariant = 'outlined';
  } else {
    buttonColor = 'error';
    buttonVariant = 'contained';
  }
  return (
    <EditDialog resource="order_items" record={record} label={label} description={description} buttonColor={buttonColor} buttonVariant={buttonVariant}>
      <RichTextInput source="customer_note" />
    </EditDialog>
  )
}
