import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import atob from "atob";

import env from "../env";
import { AuthProvider, UserIdentity } from "react-admin";

type ErrorType = {
  status: number;
};

function parseJWT(token: string) {
  const base64Url = token.split(".")[1];
  return JSON.parse(atob(base64Url));
}

const authProvider: AuthProvider = {
  login: async ({ email, password }: { email: string; password: string }) => {
    const hash = Base64.stringify(sha256(password));
    const request = new Request(`${env.API_URL}/users/sign_in`, {
      method: "POST",
      body: JSON.stringify({ email, password: hash }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    const header = response.headers.get("Authorization");
    const token = header?.split(" ")[1];
    if (!token) {
      return Promise.reject();
    }
    localStorage.setItem(env.TOKEN_KEY, token);
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem(env.TOKEN_KEY);
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem(env.TOKEN_KEY) ? Promise.resolve() : Promise.reject(),
  checkError: (error: any) => {
    const status = error?.status || error?.response?.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem(env.TOKEN_KEY);
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getIdentity: () => {
    const token = localStorage.getItem(env.TOKEN_KEY) as string;
    const payload = parseJWT(token);
    return Promise.resolve({ id: payload.sub, fullName: payload.name });
  },
  getPermissions: () => {
    const token = localStorage.getItem(env.TOKEN_KEY);
    if (token) {
      const payload = parseJWT(token);
      return Promise.resolve(payload.role);
    } else {
      return Promise.reject();
    }
  },
};

export default authProvider;
